
/*
########   ########      ###      ######    ##    ##   
   ##      ##     ##    ## ##    ##    ##   ##   ##    
   ##      ##     ##   ##   ##   ##         ##  ##     
   ##      ########   ##     ##  ##         #####      
   ##      ##   ##    #########  ##         ##  ##     
   ##      ##    ##   ##     ##  ##    ##   ##   ##    
   ##      ##     ##  ##     ##   ######    ##    ##   
*/

// Analytics + Pixel facebook
var track = function(category, name) {

	window.dataLayer = window.dataLayer || [];
	dataLayer.push({
		'event': 'Lead',
		'category': category,
		'name': name
	});

};

/* Décommenter la ligne si dessous pour activer UNISON */
//var allBreakpoints = Unison.fetch.all();

var navigation = responsiveNav(".nav-main", {
	animate: true,        // Boolean: Use CSS3 transitions, true or false
	transition: 400,      // Integer: Speed of the transition, in milliseconds
	label: "Menu",        // String: Label for the navigation toggle
	insert: "after",      // String: Insert the toggle before or after the navigation
	customToggle: "#nav-toggle",     // Selector: Specify the ID of a custom toggle
	openPos: "relative",  // String: Position of the opened nav, relative or static
	jsClass: "js",        // String: 'JS enabled' class which is added to <html> el
	init: function(){},   // Function: Init callback
	open: function(){},   // Function: Open callback
	close: function(){}   // Function: Close callback
});

/**
 * Google Map
 */
var cmsLat      = $('#program-map').attr('data-lat');
var cmsLng      = $('#program-map').attr('data-lng');
var defaultZoom = $('#program-map').attr('data-zoom');


var GoogleMap = function() {

	var image = new google.maps.MarkerImage('/images/pointer.png', null, null, null, new google.maps.Size(33,33));
	if( $('#program-map').data('pointer') ){
		image = new google.maps.MarkerImage($('#program-map').data('pointer'), null, null, null, new google.maps.Size($('#program-map').data('pointer-width'),$('#program-map').data('pointer-height')));
	}

	var stylez = [
	  {
	    featureType: "transit",
	    stylers: [
	      { hue: "#f600ff" }
	    ]
	  },{
	    featureType: "landscape",
	    stylers: [
	      { saturation: -100 }
	    ]
	  },{
	    featureType: "water",
	    stylers: [
	      { hue: "#65bcff" },
	      { lightness: -56 }    ]
	  },{
	    featureType: "road",
	    stylers: [
	      { hue: "#7f00ff" },
	      { saturation: -80 }
	    ]
	  },{
	    featureType: "poi",
	    stylers: [
	      { saturation: -100 }
	    ]
	  },{
	  }
	];

	bounds = new google.maps.LatLngBounds();

	simpleMap = new GMaps( {
		div:              '#program-map',
		lat:              cmsLat,
		lng:              cmsLng,
		zoom:             parseFloat(defaultZoom),
		disableDefaultUI: true,
		scrollwheel:      false,
		styles:           stylez
		// styles:           [{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"},{"color":"#052366"},{"saturation":"-70"},{"lightness":"85"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45},{"visibility":"on"}]},{"featureType":"road","elementType":"geometry","stylers":[{"saturation":"-18"}]},{"featureType":"road","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"road.arterial","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"road.local","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#57677a"},{"visibility":"on"}]}]
	});

	$("#js-zoomin").click(function(e){
		e.preventDefault();
		simpleMap.zoomIn(1);
	});

	$("#js-zoomout").click(function(e){
		e.preventDefault();
		simpleMap.zoomOut(1);
	});

	if( $(".program-map--single").length > 0 ){
		simpleMap.addMarker({
			lat:  cmsLat,
			lng:  cmsLng,
			icon: image
		});
	}else{
		getMarkers();
	}
};

var xhr     = null;
var markers = [];

var loadOverlay = function(_lat, _lng, _rs, _contenu, _prix){

	content = '<p>'+_contenu+'</p>';
	if( _prix ) content += '<p>'+_prix+'</p>';
	contenu = '<div class="map-overlay"><div class="overlay-content"><h6>'+_rs+'</h6>'+content+'</div></div>';

	simpleMap.removeOverlays();
	simpleMap.drawOverlay({
		lat: _lat,
		lng: _lng,
		verticalOffset:-70,
		layer:'floatPane',
		content : contenu
	});

};

var getMarkers = function(){

	$.each( $('.map-marker') , function(i,programme){

	 	var myIcon = new google.maps.MarkerImage('/images/pointer.png', null, null, null, new google.maps.Size(33,33));

	 	var id    = $(this).data('id');
		var lat   = $(this).data('lat');
		var lng   = $(this).data('lng');
		var nom   = $(this).data('nom');
		var ville = $(this).data('ville');
		var url   = $(this).data('url');
		var prix   = $(this).data('prix');

		var myMarker = simpleMap.addMarker({
			id:    id,
			lat:   lat,
			lng:   lng,
			nom:   nom,
			ville: ville,
			url:   url,
			prix:   prix,
			icon:  myIcon,
			click: function(element){
				window.location = url;
			},
			mouseover: function(e){
	            loadOverlay(lat, lng, nom, ville, prix);
	        },
			mouseout: function(e){
	            simpleMap.removeOverlays();
	        }
		});

		bounds.extend( myMarker.getPosition() );
		markers.push( myMarker );

	});

	simpleMap.panToBounds(bounds);
	simpleMap.fitBounds(bounds);

};

var getJson = function(){

	if( xhr !== null ) xhr.abort();
	var url = "/programme.html";
	if( $("#program-map").data('json') ) url = $("#program-map").data('json');

	xhr = $.ajax({
		dataType: "json",
		type: "POST",
		data : {
			"act":     "map"
		},
		url: url,
		error: function(jqXHR, textStatus, errorThrown) {
			alert("Erreur");
		},
		success: function(data){

			$.each(data.programmes, function(i,programme){

			 	var myIcon = new google.maps.MarkerImage('/images/pointer.png', null, null, null, new google.maps.Size(33,33));

				var myMarker = simpleMap.addMarker({
					id:    programme.id,
					lat:   programme.lat,
					lng:   programme.lng,
					nom:   programme.nom,
					ville: programme.ville,
					url:   programme.url,
					icon:  myIcon,
					click: function(element){
						window.location = programme.url;
					},
					mouseover: function(e){
			            loadOverlay(programme.lat, programme.lng, programme.nom, programme.ville);
			        },
					mouseout: function(e){
			            simpleMap.removeOverlays();
			        }
				});

				markers.push( myMarker );

			});
		}

	});
};

/*
########    #######   ##     ##  ########   ########      ###     ########   ##    ##   
##     ##  ##     ##  ###   ###  ##     ##  ##           ## ##    ##     ##   ##  ##    
##     ##  ##     ##  #### ####  ##     ##  ##          ##   ##   ##     ##    ####     
##     ##  ##     ##  ## ### ##  ########   ######     ##     ##  ##     ##     ##      
##     ##  ##     ##  ##     ##  ##   ##    ##         #########  ##     ##     ##      
##     ##  ##     ##  ##     ##  ##    ##   ##         ##     ##  ##     ##     ##      
########    #######   ##     ##  ##     ##  ########   ##     ##  ########      ##      
*/

$(document).ready(function() {

	FastClick.attach(document.body);

	/**
	 * Lien sur balise quelconque (avec attribut data-link)
	 */
	$('[data-link]').click(function() {
		window.location.href = $(this).attr('data-link');
	}).find('a').click(function(e) {
		e.stopPropagation();
	});

	/**
	 * Test si les Media Queries sont supportées
	 * Ajoute la classe 'mq' au tag HTML si c'est le cas
	 */
	if ( Modernizr.mq('only all') ) {
		jQuery('html').addClass('mq');
	} else {
		jQuery('html').addClass('no-mq');
	}

	/**
	 * Browser detect : masquage de la bannière
	 */
	$('.browserdetect__close').click(function(e) {
		e.preventDefault();
		$('.browserdetect').addClass('browserdetect--hidden');
	});

	/**
	 * MatchHeight [GRID]
	 */
	$(".harmonize").matchHeight();
	$(".news-item__meta").matchHeight();

	/**
	 * HEADER
	 */
	var fixednav = new Waypoint({
		element: document.getElementById('container'),
		handler: function(direction) {
			if ( direction == 'down' ) {
				$('#header-main').addClass('header-main--reduced');
				$('body').removeClass('ontop');
			} else {
				$('#header-main').removeClass('header-main--reduced');
				$('body').addClass('ontop');
			}
		}
	});

	/**
	 * QUICKLINK
	 */
	$(".nav-quicklink--top").click(function(e) {
		e.preventDefault();
		$("body,html").animate({
			scrollTop: 0
		}, 400);
		return false;
	});
	
	/**
	 * Simple Slider Home
	 */
	var duration = 5000;

	var currentIndex = 0,
	  items = $('#mcube-simple-slider > div'),
	  itemAmt = items.length;

	function cycleItems() {
	  var item = $('#mcube-simple-slider > div').eq(currentIndex);
	  items.removeClass('active');
	  item.addClass('active');
	}

	function update() {
	  currentIndex += 1;
	  if (currentIndex > itemAmt - 1) {
	    currentIndex = 0;
	  }
	  cycleItems();
	}

	var autoSlide = setInterval( update, duration);

	$('.next').click(function() {
		clearInterval(autoSlide);
		currentIndex += 1;
		if (currentIndex > itemAmt - 1) {
			currentIndex = 0;
		}
		cycleItems();
		autoSlide = setInterval( update, duration );
	});

	$('.prev').click(function() {
		clearInterval(autoSlide);
		currentIndex -= 1;
		if (currentIndex < 0) {
			currentIndex = itemAmt - 1;
		}
		cycleItems();
		autoSlide = setInterval( update, duration );
	});
	/* END Simple Slider */

	/*
	########    #######   ########   ##     ##   ######    
	##         ##     ##  ##     ##  ###   ###  ##    ##   
	##         ##     ##  ##     ##  #### ####  ##         
	######     ##     ##  ########   ## ### ##   ######    
	##         ##     ##  ##   ##    ##     ##        ##   
	##         ##     ##  ##    ##   ##     ##  ##    ##   
	##          #######   ##     ##  ##     ##   ######    
	*/
	$("form").validationEngine({
		scroll: false,
		binded: false,
		promptPosition: 'centerRight:-155,-25',
		addFailureCssClassToField: 'inputError',
		addSuccessCssClassToField: 'inputTrue',
		autoPositionUpdate: true,
		maxErrorsPerField: 1
	});

	$("#formPhone").submit(function(){		
		if($("#formPhone").validationEngine('validate')){
			var data   = $(this).serialize() + '&act=phone';
			var url    = $(this).attr("action");
			var $btn   = $(this).find("button").attr('disabled',true).find("span").html("...");
			var thank  = $(this).find(".thank");

			$.ajax({
				type    : "POST",
				data    : data,
				url     : url,
				success : function(retour) {
					track("Demande de rappel", $("input[name='telRappel']").val());
					$btn.html("envoyer");
					thank.html(retour);
				}
			});
		}
		return false;
	});

	$("#formNewsletter").submit(function(){		
		if($("#formNewsletter").validationEngine('validate')){
			var data   = $(this).serialize() + '&act=alerte';
			var url    = $(this).attr("action");
			var $btn   = $(this).find("button").attr('disabled',true).find("span").html("...");
			var thank  = $(this).find(".thank");

			$.ajax({
				type    : "POST",
				data    : data,
				url     : url,
				success : function(retour) {
					track("Alerte email", $("input[name='alertEmail']").val());
					$btn.html("valider");
					thank.html(retour);
				}
			});
		}
		return false;
	});

	//$("#FormPlan").validationEngine({ 'scroll':false });
	$("#FormPlan").submit(function(){
		if($(this).validationEngine('validate')){
			var data = $(this).serialize() + '&act=plan';
			var div = $("#plan span.retour").html("demande en cours d'envoi...");
			//$(this).hide();
			$.ajax({
				type: "POST",
				data : data,
				url: $(this).attr("action"),
				success: function(retour) {
					track("Demande de plan", "Lot #"+$("input[name='lot']").val());
					div.empty().html(retour);
					setTimeout(function(){
						$.magnificPopup.close();
					}, 2000);
				}
			});
		}
		return false;
	});

	$("#FContact").submit(function(){
		var form = $(this);
		if($(this).validationEngine('validate')){
			var data  = $(this).serialize() + '&act=envoi';
			var url   = $(this).attr("action");
			var thank = $(this).parent().find(".retour").html('Envoi du message en cours...');
			var $localite = $("input[name='localite']");
			var $offre = $("input[name='offre']");
			var $programme = $("select[name='programme']");
			$(this).empty();
			$.ajax({
				type:    "POST",
				data:    data,
				url:     url,
				success: function(retour) {
					if($localite.length) {
						track("Vente de terrain", $localite.val());
					} else {
						var trackName = "Contact";
						if($offre.length) trackName = "Offre de la semaine";
						if($programme.length) trackName = $programme.val();
						if(form.hasClass('campaign')) trackName = "1 mois pour devenir propriétaire";
						track("Formulaire de contact", trackName);
					}
					thank.html(retour);
				}
			});
		}
		return false;
	});

	/*
	########   ########    #######    ######    ########      ###     ##     ##  ##     ##  ########    ######    
	##     ##  ##     ##  ##     ##  ##    ##   ##     ##    ## ##    ###   ###  ###   ###  ##         ##    ##   
	##     ##  ##     ##  ##     ##  ##         ##     ##   ##   ##   #### ####  #### ####  ##         ##         
	########   ########   ##     ##  ##   ####  ########   ##     ##  ## ### ##  ## ### ##  ######      ######    
	##         ##   ##    ##     ##  ##    ##   ##   ##    #########  ##     ##  ##     ##  ##               ##   
	##         ##    ##   ##     ##  ##    ##   ##    ##   ##     ##  ##     ##  ##     ##  ##         ##    ##   
	##         ##     ##   #######    ######    ##     ##  ##     ##  ##     ##  ##     ##  ########    ######    
	*/
	if( $("#program-map").length ) GoogleMap();

	var IniDemandeDePlan = function(){
		$(".btnTable--plan").click(function(e){
			e.preventDefault();
			var id = $(this).attr('num');
			$('input[name=lot]').val(id);
			$("#plan .retour").empty();
			$.magnificPopup.open({
				items: {
					src: '#plan',
					type: 'inline',
					removalDelay: 300,
					mainClass: 'mfp-fade'
				}
				
			});
		});
	};

	IniDemandeDePlan();

	/*
	 ######    ########      ###     ########    ######    ##     ##  
	##    ##   ##           ## ##    ##     ##  ##    ##   ##     ##  
	##         ##          ##   ##   ##     ##  ##         ##     ##  
	 ######    ######     ##     ##  ########   ##         #########  
	      ##   ##         #########  ##   ##    ##         ##     ##  
	##    ##   ##         ##     ##  ##    ##   ##    ##   ##     ##  
	 ######    ########   ##     ##  ##     ##   ######    ##     ##  
	*/
	$('.nav-quicklink--search').magnificPopup({
		type: 'inline',
		removalDelay: 300,
		mainClass: 'mfp-fade'
	});

	$('.select2').select2({
		minimumResultsForSearch:20
	});

	/*
	 ######    ####  ##     ##  ##     ##  
	##    ##    ##   ###   ###  ##     ##  
	##          ##   #### ####  ##     ##  
	 ######     ##   ## ### ##  ##     ##  
	      ##    ##   ##     ##  ##     ##  
	##    ##    ##   ##     ##  ##     ##  
	 ######    ####  ##     ##   #######   
	*/
	$("#divresult").hide();
	
	$("#form_submit_ptz").click(function() {
		$("#form_simulateur").hide();
		$("#divresult").show();
		PTZ();
	});

	//Sliders
	$("#slider_fiscal").slider({
		min: 0, max: 150000, step: 5000, 
		slide: function(e, ui) { 
			setFiscal(ui.value);
			PTZ();
		}
	});

	$("#slider_composition").slider({
		min: 1, max: 8, step: 1, 
		slide: function(e, ui) { 
			setComposition(ui.value);
			PTZ();
		}
	});

	$("#slider_montant").slider({
		value: $("#montant_value").val(),
		start: 75000, min: 75000, max: 600000, step: 25000, 
		slide: function(e, ui) { 
			setMontant(ui.value);
			PTZ();
		}
	});
	
	$("#zone").change(PTZ);
	$("#type").change(PTZ);

	// iniSimulateur();

	$('form#Emprunt').submit(function() {
		$(this).find('.input-container--hidden').addClass('hidden');
		$(this).find('.input-container--hidden input').val('');
		verif( $(this) );
		// $(this).find('.input-container--hidden').removeClass('hidden');
		return false;		
	});

	$(".youtube-video, .fitvids").fitVids();

/*
##     ##  ####  ########   ########    #######    ######    
##     ##   ##   ##     ##  ##         ##     ##  ##    ##   
##     ##   ##   ##     ##  ##         ##     ##  ##         
##     ##   ##   ##     ##  ######     ##     ##   ######    
 ##   ##    ##   ##     ##  ##         ##     ##        ##   
  ## ##     ##   ##     ##  ##         ##     ##  ##    ##   
   ###     ####  ########   ########    #######    ######    
*/



	$(function() { 
	    var videos  = $(".video-container");

	        videos.on("click", function(){
	            var elm = $(this),
	                conts   = elm.contents(),
	                le      = conts.length,
	                ifr     = null;

	            for(var i = 0; i<le; i++){
	              if(conts[i].nodeType == 8) ifr = conts[i].textContent;
	            }

	            elm.addClass("player").html(ifr);
	            elm.off("click");
	        });
	});

	/*
	########   ########   ########    ######     ######    ########   ####  ########   ########   ########   ##     ##  ########   
	##     ##  ##     ##  ##         ##    ##   ##    ##   ##     ##   ##   ##     ##     ##      ##         ##     ##  ##     ##  
	##     ##  ##     ##  ##         ##         ##         ##     ##   ##   ##     ##     ##      ##         ##     ##  ##     ##  
	########   ########   ######      ######    ##         ########    ##   ########      ##      ######     ##     ##  ########   
	##         ##   ##    ##               ##   ##         ##   ##     ##   ##            ##      ##         ##     ##  ##   ##    
	##         ##    ##   ##         ##    ##   ##    ##   ##    ##    ##   ##            ##      ##         ##     ##  ##    ##   
	##         ##     ##  ########    ######     ######    ##     ##  ####  ##            ##      ########    #######   ##     ##  
	*/

	function getUrlVars() {
		var vars = {};
		var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
			vars[key] = value;
		});
		return vars;
	}

	if( getUrlVars().modal ){
		$.magnificPopup.open({
		    items: {
		        src: '#'+getUrlVars().modal 
		    },
		    type: 'inline'
		});
	}

	$('.popup-with-form--option').click(function(e) {
		$("#fOption input[type!='radio'], #fOption textarea").val('');
    	$("#fOption input[name='~lot']").val( $(this).data('lot') );
    	$("#option-prescripteur .prescripteur--modal__number").html( $(this).data('num') );
	});

	$('.popup-with-form--reservation').click(function(e) {
		$("#fReservation input[type!='radio'], #fReservation textarea").val('');
    	$("#fReservation input[name='~lot']").val( $(this).data('lot') );
    	$("#reservation-prescripteur .prescripteur--modal__number").html( $(this).data('num') );
	});

	$('.popup-with-form--validation').click(function(e) {
		$("#fValidation input[type!='radio'], #fValidation textarea").val('');
    	$("#fValidation input[name='~reservation']").val( $(this).data('reservation') );
    	$("#fValidation input[name='~lot']").val( $(this).data('lot') );
    	$("#validation-prescripteur .prescripteur--modal__number").html( $(this).data('num') );
	});

	$('.popup-with-form--denonciation').click(function(e) {
		$("#fDenonciation input[type!='radio'], #fDenonciation textarea").val('');
    	$("#fDenonciation input[name='~lot']").val( $(this).data('lot') );
    	$("#denonciation-prescripteur .prescripteur--modal__number").html( $(this).data('num') );
	});

	$('.popup-with-form--alerte').click(function(e) {
    	$("#fAlerte input[name='~reservation']").val( $(this).data('reservation') );
    	$("#alerte-prescripteur .prescripteur--modal__number").html( $(this).data('num') );
	});

	$('.popup-with-form').magnificPopup({
		type:      'inline',
		preloader: false,
		focus:     '#name',
		callbacks: {
			beforeOpen: function() {
				$("body").addClass('noscroll');
				$(document).bind('touchmove', function(e) {
					e.preventDefault();
				});
			},
			beforeClose: function() {
				$("body").removeClass('noscroll');
				$(document).unbind('touchmove');
			}
		}
	});

	$('.popup-with-form-ajax').magnificPopup({
		type: 'ajax',
		preloader: false,
		focus:     '#_femail_client_nom',
		callbacks :{
			ajaxContentAdded : function(){

				formValidation();

				var fileInput = $( ".input-file" ),  
				button        = $( ".input-file-trigger" );

				// action lorsque le label est cliqué
				button.on( "click", function( event ) {
					fileInput.trigger('click');
					return false;
				});

			}
		}
	});

	$("#fLoginPrescripteur").submit(function(){
		if($(this).validationEngine('validate')){
			var data  = $(this).serialize() + '&act=login';
			var url   = $(this).attr("action");
			var thank = $(this).find(".retour").html('Connection en cours...');
			$.ajax({
				type:    "POST",
				data:    data,
				url:     url,
				success: function(retour) {
					try {
				        var data = JSON.parse(retour);
				    	if( data.error ) thank.html(data.error);
				    	if( data.redirect ) window.location = data.redirect;
				    } catch (e) {
				        return false;
				    }
				}
			});
		}
		return false;
	});

	$("#fOption, #fDenonciation, #fReservation, #fValidation, #fEmailClient, #fAlerte").submit(function(){

        if($(this).validationEngine('validate')){

            var data      = $(this).serialize() + '&act=envoi';
            var url       = $(this).attr("action");
            var divRetour = $(this).next(".retour").html('envoi en cours...');

            $.ajax({
                type    : "POST",
                data    : data,
                url     : url,
                success : function(retour) {
                	try {
				        var data = JSON.parse(retour);
				    	if( data.error ) divRetour.html(data.error);
				    	if( data.msg ) divRetour.html(data.msg);
				    	if( data.redirect ){
				    		setTimeout(function(){ 
				    			window.location = data.redirect; 
				    		}, 2500);
				    		return false;
				    	}
				    	if( ! data.error ) setTimeout(location.reload(), 3000);
				    } catch (e) {
				    	console.log('error');
				        return false;
				    }
                }
            });

        }
        return false;
    });

    var filters = {};

	function concatValues( obj ) {
		var value = '';
		for ( var prop in obj ) {
			value += obj[ prop ];
		}
		return value;
	}

    $("select[name='js-lot-filter']").change(function(){
    	var $this = $(this);
    	var filterGroup = $this.attr('data-filter');
    	filters[ filterGroup ] = $this.val();
    	var filterValue = concatValues( filters );
    	$(".table-values").hide();
    	$(".table-values"+filterValue).show();

    	$('.program-table').each(function(){
	    	$(this).show().prev('p.title-lev1').show();
	    	if( $('.table-values:visible', this).length === 0 ){
	    		$(this).hide().prev('p.title-lev1').hide();
	    	}
	    });
    });

});

function makeFileList() {

	// initialisation des variables
	var input = document.getElementById('my-files');
	var list  = document.getElementById('file-return');

	while (list.hasChildNodes()) {
		list.removeChild(list.firstChild);
	}

	for (var i = 0; i < input.files.length; i++) {
		var li = document.createElement("li");
		li.innerHTML = input.files[i].name;
		list.appendChild(li);
	}

	if(!list.hasChildNodes()) {
		var no_li = document.createElement("li");
		no_li.innerHTML = 'Aucun fichier';
		list.appendChild(no_li);
	}

}

function formValidation(){

	$("#fEmailClient").submit(function(){

        if($(this).validationEngine('validate')){

            var data      = $(this).serialize() + '&act=envoi';
            var url       = $(this).attr("action");
            var divRetour = $(this).next(".retour").html('envoi en cours...');

            var form = $(this);
			var formData = new FormData(document.getElementById("fEmailClient"));// get the form data
			formData.append('act', 'envoi');

            $.ajax({
                type:    "POST",
                data:    formData,
                url:     url,
                processData: false,
				contentType: false,
                success: function(retour) {
                	try {
				        var data = JSON.parse(retour);
				    	if( data.error ) divRetour.html(data.error);
				    	if( data.msg ) divRetour.html(data.msg);
				    	if( data.redirect ){
				    		setTimeout(function(){ 
				    			window.location = data.redirect; 
				    		}, 2500);
				    		return false;
				    	}
				    	setTimeout(location.reload(), 3000);			    	
				    } catch (e) {
				    	console.log('error');
				        return false;
				    }
                }
            });

        }
        return false;
    });

}