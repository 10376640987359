//Tranches d'imposition 2010 => "val" représente l'intervalle entre le revenu minimal et le revenu maximal de cette tranche d'où le "Infinity" dans le "val" de la dernière tranche, "%" représente le TMI de cette tranche
var tranches = new Array(
	{'val':5875,'%':0},			// 0 -> 5875
	{'val':5845,'%':5.5},		// 5875 -> 11720
	{'val':14310,'%':14},		// 11720 -> 26030
	{'val':43753,'%':30},		// 26030 -> 69783
	{'val':Infinity,'%':40}		// + de 69783
)

//Fonctions sur les slides	
function adjustValue(min, max, val) //Permet de réajuster la valeur d'un input si elle est non comprise dans l'intervalle [min,max]
{
	if(!(val>=min && val<=max)) //Si la valeur n'est pas dans l'intervalle [min,max] (/!\ => supérieure à max, inférieure à min, OU BIEN n'est pas un nombre)
	{
		if(val>max) val=max;
		else val=min; //Si la valeur est sous le minimum OU N'EST PAS UN NOMBRE on la met au minimum.
	}
	return val;
}

function getCursorPercentage(min, max, val) //Permet de retourner la position du curseur en pourcentage
{
	return (val-min)*100/(max-min);
}
	
function findNearest(includeLeft, includeRight, value, values) //Permet de placer la valeur actuelle du curseur (value) en fonction des valeurs autorisées (values) => Calcul de la valeur la plus proche
{
	var nearest = null;
	var diff = null;
	for (var i = 0; i < values.length; i++) {
		if ((includeLeft && values[i] <= value) || (includeRight && values[i] >= value)) {
			var newDiff = Math.abs(value - values[i]);
			if (diff == null || newDiff < diff) {
				nearest = values[i];
				diff = newDiff;
			}
		}
	}
	return nearest;
}
      
  //getters/setters
function getDispositif(){ return parseInt($("select[name=dispositif]>option:selected").val()); }
function getProfession(){ return parseInt($("#liberal:checked").val()); }
function getImposition(){ return parseFloat($("#imposition_value").val()); }
function setImposition(imposition){ $('#imposition_value').val(imposition); }
function getTMI(){ return parseFloat($("#tmi_value").val()); }
function setTMI(tmi){ $('#tmi_value').val(tmi); $("#slider_tmi").slider('option', 'value', tmi); }
function getNbParts(){ return parseFloat($("#nb_parts_value").val()); }
function setNbParts(nbparts){ $('#nb_parts_value').val(nbparts); $("#slider_nb_parts").slider('option', 'value', nbparts); }
function getRevenu(){ return parseFloat($("#revenu_value").val()); }
function setRevenu(revenu){ $('#revenu_value').val(revenu); }
function getRevenuFoncier(){ return parseFloat($("#revenu_foncier_value").val()); }
function setRevenuFoncier(revenu){ $('#revenu_foncier_value').val(revenu); }
function getMontantInvestissement(){ return parseFloat($('#montant_investissement_value').val()); }
function setMontantInvestissement(montant_investissement){ $('#montant_investissement_value').val(montant_investissement); }
function getDureeCredit(){ return parseInt($('#duree_credit_value').val()); }
function setDureeCredit(duree_credit){ $('#duree_credit_value').val(duree_credit); }
function getTauxCredit(){ return parseFloat($('#taux_credit_value').val()); }
function setTauxCredit(taux_credit){ $('#taux_credit_value').val(taux_credit); }
function getDureeSimulation(){ return parseInt($('#duree_simulation_value').val()); }
function setDureeSimulation(duree_simulation){ $('#duree_simulation_value').val(duree_simulation); }
function getEconomieImpot(){ return parseFloat($("#economie_impot").html()); }
function setEconomieImpot(economie_impot){ $('#economie_impot').html(economie_impot); }
function setCapitalConstitue(capital){ $('#capital_constitue').html(capital); }
function getEpargneMoyenne(){ return parseFloat($('#epargne_moyenne').html()); }
function setEpargneMoyenne(epargne){ $('#epargne_moyenne').html(epargne); }
function setAssuranceVie(montant){ $('#assurance_vie').html(montant); }
function setLivretA(montant){ $('#livret_a').html(montant); }
function setPEL(montant){ $('#pel').html(montant); }

// getters/setters specifiques PTZ+
function getComposition(){ return parseFloat($("#composition_value").val()); }
function setComposition(composition){ $('#composition_value').val(composition); $("#slider_composition").slider('option', 'value', composition); }
function getMontant(){ return parseFloat($('#montant_value').val()); }
function setMontant(montant){ $('#montant_value').val(montant); }
function getFiscal(){ return parseFloat($('#fiscal_value').val()); }
function setFiscal(montant){ $('#fiscal_value').val(montant); }

      
function getPercentageReductionImpot()// retourne le pourcentage d'économies en fonction du dispositif ainsi que de la durée de l'investissement
{
	var pourcentage = 0;
	switch (getDispositif()){
		case 1: // Duflot
			pourcentage=0.18;
			break;
		case 99: //Scellier classique
			pourcentage=0.13;
			break;
		case 2: //Scellier meublé
			pourcentage=0.18;
			break;
		case 4: //Scellier intermédiaire
			switch (getDureeSimulation()){
				case 9:
					pourcentage=0.13;
					break;
				case 12:
					pourcentage=0.18;
					break;
				case 15:
				case 18:
				case 20:
				case 25:
					pourcentage=0.23;
					break;
			};
			break;
		case 3: // Scellier outre mer
			switch (getDureeSimulation()){
				case 9: 
					pourcentage=0.36;
					break;
				case 12:
					pourcentage=0.41;
					break;
				case 15:
				case 18:
				case 20:
				case 25:
					pourcentage=0.46;
					break;
			};
			break;
	};
	return pourcentage;
}
      
function getMensualite()
{
	//Formule : (capital*taux/12)/(1-(1+(taux/12))^(-durée)
	var c = getMontantInvestissement();
	var t = getTauxCredit()/100; //On souhaite le taux en tant que coefficient (pour 3% on veut 0.03 et non 3 !)
	var d = getDureeCredit()*12;
	return (c*t/12)/ (1-Math.pow((1+(t/12)),(-d)));
}
	
function getDeficitFoncier()//deficit foncier annuel
{
	var abattement = 0;
	var loyer = getLoyer()*12; //loyer annuel
	if(getDispositif() == 4) //si scellier intermédiaire
	{
		abattement = loyer*0.3; //abattement annuel
	}
	var duree = getDureeCredit();
	var interets = (12*duree*getMensualite() - getMontantInvestissement())/duree; //interets annuels
	return loyer-interets-abattement;
}
	
function getLoyer() //loyer mensuel
{
	var rentabilite = 0;
	switch (getDispositif()) {
		case 1: //scellier classique   
			rentabilite = 0.032;
			break;
		case 4: //scellier intermédiaire
		case 3: //scellier outre-mer 
			rentabilite = 0.03;
			break;
		case 2: //scellier meublé
			rentabilite = 0.042;
			break;
	}
	return rentabilite*getMontantInvestissement()/12;	
}
	
function getMontantReport()
{
	var reduc_annuelle = getPercentageReductionImpot()*getMontantInvestissement()/getDureeSimulation();
	var imposition = getImposition();
	if(reduc_annuelle > imposition) //On regarde si le report peut se faire (réduction d'impôts annuelle supérieure à l'imposition annuelle)
	{
		if(6*(reduc_annuelle - imposition) > 6*imposition) //le report est limité à 6*l'imposition annuelle
			return 6*imposition;
		return 6*(reduc_annuelle - imposition);
	}
	else return 0;
}
      
function getPlacementAssuranceVie()
{
	var epargne = getEpargneMoyenne();
	if(epargne<0)
		return 0;
	var epargne_moyenne = epargne;
	var interets;
	var taux_mensuel = Math.pow(1.045,(1/12))-1;
	for(var i=0; i<getDureeSimulation(); i++)
	{
		interets = 0;
		for(var j=0; j<12; j++)
		{
			interets += epargne*taux_mensuel;
			epargne += epargne_moyenne;
		}//fin d'année
		interets *= 0.879; //prélèvements sociaux de 12.1% en décembre 
		epargne += interets;             
	}
	return epargne;
}
      
function getPlacementLivretA()
{
	var epargne = getEpargneMoyenne()/2;
	if(epargne<0)
		return 0;
	var epargne_moyenne = epargne;
	var interets;
	var taux_quinzaine = Math.pow(1.0125,(1/24))-1;
	for(var i=0; i<getDureeSimulation(); i++)
	{
		interets = 0;
		for(var j=0; j<24; j++)
		{
			interets += epargne*taux_quinzaine;
			epargne += epargne_moyenne;
		}//fin d'année
		epargne += interets;              
	}
	return epargne;            
}
      
function getPlacementPEL()
{
	var epargne = getEpargneMoyenne();
	if(epargne<45) //Le versement annuel minimum sur un PEL est de 540€ (soit 45€ / mois) 
	return 0;
	var epargne_moyenne = epargne;
	var interets;
	var taux_mensuel = Math.pow(1.025,(1/12))-1;
	for(var i=0; i<getDureeSimulation(); i++)
	{
		interets = 0;
		for(var j=0; j<12; j++)
		{
			interets += epargne*taux_mensuel;
			epargne += epargne_moyenne;
		}//fin d'année
		interets *= 0.879; //prélèvements sociaux de 12.1% en décembre 
		epargne += interets;             
	}
	return epargne;      
}
      
function imposition()
{
	var min = 0;
	var max = 8833;
	var nbParts = getNbParts();
	var revenu = getRevenu();
	if(getProfession()==0) revenu *= 0.9; //abattement de 10% sur les revenus salariés
	revenu += getRevenuFoncier();
	revenu /= nbParts;
	var impots = 0;
	for(var i=0;i<tranches.length;i++)
	{
		if(revenu - tranches[i]['val'] <= 0)
		{
			impots += revenu*(tranches[i]['%']/100);
			setTMI(tranches[i]['%']);
			setImposition(Math.round(impots*nbParts));
			$("#slider_imposition #cursor1").css('left', getCursorPercentage(min,max,adjustValue(min, max, getImposition()))+'%'); //on calcule le pourcentage pour positionner le slider
			return;
		}
		else
		{
			impots+=tranches[i]['val']*(tranches[i]['%']/100);
			revenu-=tranches[i]['val'];
		}
	}
}
      
function economieImpot()
{
	var reduction = getMontantInvestissement()*getPercentageReductionImpot(); //réduction = montant investissement * % d'économie d'impôt (dans la limite des impôts payés)
	if(reduction > getImposition()*getDureeSimulation())
		  reduction = getImposition()*getDureeSimulation(); //on limite la réduction d'impôt à l'imposition annuelle multipliée par la durée de l'investissement
	var dispositif = getDispositif();
	if (dispositif != 2) //si on est pas en scellier meublé
	{        
		  var deficitFoncier = getDeficitFoncier();
		  if(deficitFoncier<0) //s'il y a un déficit foncier
		  {
				deficitFoncier = (-1)*getDureeSimulation()*deficitFoncier;
				if(getRevenuFoncier()<=0) //s'il n'y a pas de revenu foncier
				{
					  reduction += deficitFoncier; //on ajoute le déficit foncier à l'économie d'impôt
					  if(reduction > getMontantInvestissement()*getPercentageReductionImpot())
							reduction = getMontantInvestissement()*getPercentageReductionImpot(); //on limite la réduction d'impôt à l'imposition annuelle multipliée par la durée de l'investissement
					  $("#texte_csgcrds").html(0);
				}
				else
				{
					  var csgcrds = 0.121*deficitFoncier; //on économise le csg/crds à hauteur de 12.1% des revenus fonciers totaux
					  $("#texte_csgcrds").html(Math.round(csgcrds));
					  reduction += csgcrds;
				}                        
		  }
		  else $("#texte_csgcrds").html(0);
	}
	else $("#texte_csgcrds").html(0);
	reduction += getMontantReport();
	setEconomieImpot(Math.round(reduction));
}
      
function capitalConstitue()
{
	var capital_restant = getMontantInvestissement();
	var mensualite = getMensualite();
	var taux_mensuel = Math.pow(1+getTauxCredit()/100,1/12)-1; //getTauxCredit()/1200;
	for(var i=0; i<12*getDureeSimulation(); i++)
	{
		capital_restant -= (mensualite - capital_restant*taux_mensuel);
	}
	if(capital_restant < 0) capital_restant = 0;
	setCapitalConstitue(Math.round(getMontantInvestissement()-capital_restant));
}

function epargne()
{
	setEpargneMoyenne(Math.round(getMensualite() - getLoyer() - getEconomieImpot()/(getDureeSimulation()*12))); //epargne = mensualité - loyer - économie d'impôts (arrondi au centième : round(val*100)/100)
	setAssuranceVie(Math.round(getPlacementAssuranceVie()));
	setLivretA(Math.round(getPlacementLivretA()));
	setPEL(Math.round(getPlacementPEL()));
}
      
function graphique()
{
	var investissement = getMontantInvestissement();
	 
	var etat = getEconomieImpot()/investissement*100;
	if(etat > 100)
		  etat = 100; //cas où l'état prend en charge l'intégralité de l'investissement à travers l'économie d'impôts.
	var locataire = (getLoyer()*12*getDureeSimulation()*100)/investissement;
	if(locataire > 100-etat)
		  locataire = 100-etat; //cas où le locataire rembourse plus que le pourcentage restant.
	var vous = Math.round((100-etat-locataire)*100)/100;
	etat = Math.round(etat*100)/100;
	locataire = Math.round(locataire*100)/100;
	$("#graph").html('<img src="http://chart.apis.google.com/chart?cht=p3&chd=t:'+etat+','+locataire+','+vous+'&chs=600x150&chco=6d1e7ecc|88a709cc|9dc107cc&chl=L&apos;Etat (Réduction de '+etat+' %)|Votre locataire ('+locataire+'%)|Vous ('+vous+'%)" />');
}
      
function calcul()
{
	$("#texte_duree").html(getDureeSimulation());
	imposition();
	economieImpot();
	capitalConstitue();
	epargne();
	graphique();
}

function iniSimulateur()
{
  /*
	$("#slider_revenu").slider('enable');
	$("#slider_nb_parts").slider('enable');
	$("#slider_revenu_foncier").slider('enable');
	$("#slider_investissement").slider('enable');
	$("#slider_duree_credit").slider('enable');
	$("#slider_taux_credit").slider('enable');
	$("#slider_duree_simulation").slider('enable');
	$("#dispositif").removeAttr("disabled");
	$("#imposition_value").removeAttr("disabled");
	$("#tmi_value").removeAttr("disabled");
	$("#revenu_value").removeAttr("disabled");
	$("#nb_parts_value").removeAttr("disabled");
	$("#revenu_foncier_value").removeAttr("disabled");
	$("#montant_investissement_value").removeAttr("disabled");
	$("#duree_credit_value").removeAttr("disabled");
	$("#taux_credit_value").removeAttr("disabled");
	$("#duree_simulation_value").removeAttr("disabled");
	$("#liberal").parent().children("input[type=radio]").each(function(){$(this).removeAttr("disabled");});
	*/
	
	//inputs/selects
	$("#revenu_value").change(function() {
		var min = 0;
		var max = 150000;
		setRevenu(adjustValue(min, max, getRevenu())); //correction de la valeur en input  
		$("#slider_revenu .ui-slider-handle").css('left', getCursorPercentage(min,max,getRevenu())+'%'); //on calcule le pourcentage pour positionner le slider
		calcul();
	});
	
	$("#revenu_foncier_value").change(function() //bouge le curseur quand la valeur de l'input change
	{
		var min = 0;
		var max = 10700;
		setRevenuFoncier(adjustValue(min, max, getRevenuFoncier())); //correction de la valeur en input  
		$("#slider_revenu_foncier #cursor1").css('left', getCursorPercentage(min,max,getRevenuFoncier())+'%'); //on calcule le pourcentage pour positionner le slider
		calcul();
	});
	$("#montant_investissement_value").change(function() //bouge le curseur quand la valeur de l'input change
	{
		var min = 75000;
		var max = 300000;
		setMontantInvestissement(adjustValue(min, max, getMontantInvestissement())); //correction de la valeur en input  
		$("#slider_investissement #cursor1").css('left', getCursorPercentage(min,max,getMontantInvestissement())+'%'); //on calcule le pourcentage pour positionner le slider
		calcul();
	});
	
	$("#simulateurform #dispositif").change(function(){ //recalcule les valeurs si le dispositif change
		calcul();
	});
	
	$("#simulateurform #liberal").change(function(){ //recalcule les valeurs si la profession change
		calcul();
	});
	//inputs/selects 
	
	
	/**** PTZ ****/
	$("#fiscal_value").change(function() {
		var min = 0;
		var max = 150000;
		setFiscal(adjustValue(min, max, getFiscal())); //correction de la valeur en input  
		$("#slider_fiscal .ui-slider-handle").css('left', getCursorPercentage(min,max,getFiscal())+'%'); //on calcule le pourcentage pour positionner le slider
		PTZ();
	});
	
	$("#montant_value").change(function() {
		var min = 75000;
		var max = 300000;
		setMontant(adjustValue(min, max, getMontant())); //correction de la valeur en input  
		$("#slider_montant .ui-slider-handle").css('left', getCursorPercentage(min,max,getMontant())+'%'); //on calcule le pourcentage pour positionner le slider
		PTZ();
	});
	           
}



// ************************************************** //
// *********************** PTZ ********************** //
// ************************************************** //

/*
var ZONE_A  =  1;
var ZONE_B1 = 12;
var ZONE_B2 = 22;
var ZONE_C  =  3;

var TACHAT_N_BBC =  1;
var TACHAT_N_NUL =  2;
var TACHAT_A_ABC = 11;
var TACHAT_A_DEF = 12;
var TACHAT_A_G   = 13;


//---PrixMax[][i][], QuotiteMax[][i], tmr[].RgiMax[i]----
var RGLPTZP_IZ_A  =0; // en zone A
var RGLPTZP_IZ_B1 =1; // en zone B1
var RGLPTZP_IZ_B2 =2; // en zone B2
var RGLPTZP_IZ_C  =3; // en zone C

//---PrixMax[i][][]-------------------
var RGLPTZP_I1_NEUF   =0;
var RGLPTZP_I1_ANCIEN =1;

//---PrixMax[][][i], CoeffMenage[i]---
var RGLPTZP_IP_1PERSO =0;
var RGLPTZP_IP_2PERSO =1;
var RGLPTZP_IP_3PERSO =2;
var RGLPTZP_IP_4PERSO =3;
var RGLPTZP_IP_5PLUS  =4;

var TACHAT_I_N_BBC    =0;
var TACHAT_I_N_NUL    =1;
var TACHAT_I_A_ABC    =2;
var TACHAT_I_A_DEF    =3;
var TACHAT_I_A_G      =4;

*/

var g_brm = GetBaremePtz();



function GetBaremePtz() {
	var b=new Object();
	// bareme au 27/10/2010

	b = {
		PrixMax: [
			[
				[156000, 218000, 265000, 312000, 359000], // NeufAncien/AB1B2C/NbPers
				[117000, 164000, 199000, 234000, 269000],
				[ 86000, 120000, 146000, 172000, 198000],
				[ 79000, 111000, 134000, 158000, 182000]
			],
			[
				[124000, 174000, 211000, 248000, 285000],
				[ 93000, 130000, 158000, 186000, 214000],
				[ 86000, 120000, 146000, 172000, 198000],
				[ 79000, 111000, 134000, 158000, 182000]
			]
		],
		QuotiteMax: [
			[38,33,29,24], // Neuf BBC
			[26,21,16,14], // Neuf Non BBC
			[10,10,10,10] // ancien HLM
		],
		CoeffMenage:  [1, 1.4, 1.7, 2, 2.3, 2.6, 2.9, 3.2],
		TRgiMax: [43500, 30500, 26500, 26500], // AB1B2C
		NbTranche:6,
		tmr: [
			[
				{RgiMax: [23000,18000,14000,11500], NbMoisP1: 276, NbMoisP2:  24, PCentAmortP2 : 15},
				{RgiMax: [25500,19500,15000,13000], NbMoisP1: 276, NbMoisP2:   0, PCentAmortP2 :  0},
				{RgiMax: [28500,21500,16500,14000], NbMoisP1: 240, NbMoisP2:   0, PCentAmortP2 :  0},
				{RgiMax: [31000,23500,18000,15000], NbMoisP1: 192, NbMoisP2:   0, PCentAmortP2 :  0},
				{RgiMax: [36000,26000,20000,18500], NbMoisP1: 144, NbMoisP2:   0, PCentAmortP2 :  0},
				{RgiMax: [43500,30500,26500,26500], NbMoisP1:  96, NbMoisP2:   0, PCentAmortP2 :  0}
			],
			[
				{RgiMax: [20500,16000,12500,11500], NbMoisP1: 276, NbMoisP2:  24, PCentAmortP2 : 15},
				{RgiMax: [23000,18000,14000,13000], NbMoisP1: 276, NbMoisP2:   0, PCentAmortP2 :  0},
				{RgiMax: [25500,19500,15000,14000], NbMoisP1: 240, NbMoisP2:   0, PCentAmortP2 :  0},
				{RgiMax: [28500,21500,16500,15000], NbMoisP1: 192, NbMoisP2:   0, PCentAmortP2 :  0},
				{RgiMax: [31000,23500,18000,16500], NbMoisP1: 144, NbMoisP2:   0, PCentAmortP2 :  0},
				{RgiMax: [43500,30500,26500,26500], NbMoisP1:  96, NbMoisP2:   0, PCentAmortP2 :  0}
			]
		]
	};
	
	return(b);
}

function FormateVal(val, NbDeci, flag) {
	var s="";
	if(val!=null && !isNaN(val))
		{
		  var l,i,tmp,signe;
	
		  var separe=" ";
		  if(flag!=null)
			  { if(flag & 0x0001) separe="&nbsp;"; else
				if(flag & 0x0002) separe="."; else
				if(flag & 0x0004) separe="";
			  }
	
		  if(NbDeci==2) { val=val*100.0; } else
		  if(NbDeci>0) { for(i=0; i<NbDeci; i++) { val=val*10; } }
		  else{ NbDeci=0; }
	
		  val=Math.round(val);
	
		  if(val<0) { val=-val; signe=1; } else{ signe=0; }
		  s=val.toString();
	
		  l=s.length;
		  for(i=NbDeci+1-l; i>0; i--) s="0"+s;
	
		  tmp=l-NbDeci;
		  if(tmp>3 && separe.length>0)
			  { for(i=0; i<5; i++)
					{ if (tmp<4) break;
					  tmp-=3;
					  s=s.substring(0, tmp)+separe+s.substring(tmp, l);
					  l+=separe.length;
					}
			  }
		  if(NbDeci>0)
			  { l=s.length;
				s=s.substring(0, l-NbDeci)+","+s.substring(l-NbDeci, l);
			  }
		  if(signe) s="-"+s;
		}
	
	return(s);
}

function FormateAnMois(nMois) {
	var s="";
	var NbMois=(nMois%12);
	var NbAn  =Math.floor(nMois/12);
	
	if(NbAn>0)
		{ s+=""+NbAn+" an";
		  if(NbAn>1) s+="s";
		}
	if(NbMois>0)
		{ if(NbAn>0) s+=" et ";
		  s+=""+NbMois+" mois";
		}
	
	return(s);
}


function MainGetForm() {
	var fd = new Object();
	f = document.simulateurform;
	
	fd.ZoneABC      = $('#zone').val();
	fd.TypeAchat    = $('#type').val();
	fd.IsAncien     = (fd.TypeAchat == 'N_BBC' || fd.TypeAchat == 'N_NUL') ? 0 : 1;
	fd.NbPersonne	= parseInt($('#composition_value').val());
	fd.RevFiscRef	= parseInt($('#fiscal_value').val());
	fd.PrixLogement	= parseInt($('#montant_value').val());
	
	switch(fd.TypeAchat) {
		case 'N_BBC':
			fd.ita = 0;
			break;
		case 'N_NUL':
			fd.ita = 1;
			break;
		case 'HLM':
			fd.ita = 2;
			break;
	}
	
	switch(fd.ZoneABC) {
		case 'A':
			fd.iz = 0;
			break;
		case 'B1':
			fd.iz = 1;
			break;
		case 'B2':
			fd.iz = 2;
			break;
		case 'C':
			fd.iz = 3;
			break;
	}
	
	return(fd);
}


function GetResultPtzPlus(fdata, brm) {
	var r=new Object();
	
	r.PretMax   =0;
	r.NbMoisTotal=0;
	r.NbMoisP1  =0;
	r.NbMoisP2  =0;
	r.PCentAmortP1=0;
	r.PCentAmortP2=0;	
	r.EchDiff   =0;
	r.EchAmort  =0;	
	r.TypeDiff  =0;
	
	
	var iNbPers;
	var NbPersonne;
	var indice1;
	var PretMarRGL;
	var tmp;
	
	iNbPers     =fdata.NbPersonne-1;
	NbPersonne  =fdata.NbPersonne;
	if(iNbPers   >4) iNbPers=4;
	if(NbPersonne>8) NbPersonne=8;
	
	
	r.Plafond = brm.PrixMax[fdata.IsAncien][fdata.iz][iNbPers];
	r.Quotite = brm.QuotiteMax[fdata.ita][fdata.iz];
	
	if(fdata.IsAncien && fdata.IsHLM) {
		r.Quotite+=brm.QuotiteHlmAdd;
	}
	
	PretMarRGL = r.Plafond * (r.Quotite / 100);
	r.PretMax  = fdata.PrixLogement * (r.Quotite / 100);
	
	if(r.PretMax > PretMarRGL) {
		r.PretMax = PretMarRGL;
	}
	
	if(r.PretMax > (fdata.PrixLogement/2)) {
		r.PretMax = fdata.PrixLogement/2;
	}
	
	tmp = Math.floor(fdata.PrixLogement/10+0.5);
	r.Revenu = (tmp > fdata.RevFiscRef) ? tmp : fdata.RevFiscRef;
	
	for(indice1=0; ; indice1++) {
		var Omr   = brm.tmr[fdata.IsAncien][indice1];
		var RgiMax= Omr.RgiMax[fdata.iz];
		
		if(RgiMax==0 || r.Revenu <= RgiMax * brm.CoeffMenage[NbPersonne-1]+0.005) {
			r.NbMoisP1     =Omr.NbMoisP1;
			r.NbMoisP2     =Omr.NbMoisP2;
			r.PCentAmortP2 =Omr.PCentAmortP2;
			r.PCentAmortP1 =100-r.PCentAmortP2;
			break;
		}
	}
	
	r.EchPeriode1=(r.PretMax*(r.PCentAmortP1/100))/r.NbMoisP1;
	r.EchPeriode2=(r.NbMoisP2>0) ? (r.PretMax*(r.PCentAmortP2/100))/r.NbMoisP2 : 0;
	
	r.TypeDiff=(r.NbMoisP2==0) ? 0
		  :(r.PCentAmortP2<100) ? 1 : 2;
	
	var ORes = {
		PretMax      : Math.round(r.PretMax),
		NbMoisTotal  : r.NbMoisP1+r.NbMoisP2,
		NbMoisP1     : r.NbMoisP1,
		NbMoisP2     : r.NbMoisP2,
		PCentAmortP2 : r.PCentAmortP2,
		EchP1        : Math.round(r.EchPeriode1*100)/100,
		EchP2        : Math.round(r.EchPeriode2*100)/100
	};
	
	return(ORes);
}


function PTZ() {
	
	var fdata;
	var ORes=new Object();
//	var f=document.ffff;
	gIsChange1=0;

	fdata = MainGetForm();
	
	if(fdata.NbPersonne) {
		ORes=GetResultPtzPlus(fdata, g_brm);
	}
	
	if(ORes.PretMax) {
		ORes.S_NbMois         =""+FormateAnMois(ORes.NbMoisP1+ORes.NbMoisP2);///+" mois";
		ORes.S_DureePeriode1  =""+FormateAnMois(ORes.NbMoisP1);///+ " mois";
		ORes.S_DureePeriode2  =""+FormateAnMois(ORes.NbMoisP2);///+" mois";
		ORes.S_Pcent1         =""+(100-ORes.PCentAmortP2)+"%";
		
		ORes.S_Pret           =FormateVal(ORes.PretMax   , 0)+"&nbsp;&euro;";
		ORes.S_EchPeriode1    =FormateVal(ORes.EchP1     , 2)+"&nbsp;&euro;";
		ORes.S_EchPeriode2    =FormateVal(ORes.EchP2     , 2)+"&nbsp;&euro;";
	} else {
		ORes.S_NbMois         ="";
		ORes.S_DureePeriode1  ="";
		ORes.S_DureePeriode2  ="";
		ORes.S_Pcent1         ="";
		
		ORes.S_Pret           ="";
		ORes.S_EchPeriode1    ="";
		ORes.S_EchPeriode2    ="";
	}
	
	$("#r_pret").html(ORes.S_Pret);
	$("#r_mois").html(ORes.S_NbMois);
	$("#r_duree1").html(ORes.S_DureePeriode1);
	$("#r_duree2").html(ORes.S_DureePeriode2);
	$("#r_mensualite1").html(ORes.S_EchPeriode1);
	$("#r_mensualite2").html(ORes.S_EchPeriode2);
	$("#r_mensualite").html(ORes.S_EchPeriode1);
	$("#r_partiel").html("Durée de la p&eacute;riode 1"+((ORes.PCentAmortP2) ? " ("+ORes.S_Pcent1+")" : "")+" : ");

	if(ORes.PCentAmortP2) {
		$("#IfTypeDiff1_duree").show();
		$("#IfTypeDiff0_ech").hide();
		$("#IfTypeDiff1_ech").show();
	} else {
		$("#IfTypeDiff1_duree").hide();
		$("#IfTypeDiff0_ech").show();
		$("#IfTypeDiff1_ech").hide();
	}
}